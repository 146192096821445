/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import alliedExpressLogo from 'assets/images/alliedExpressLogo.png'
import ausPostInternationalLogo from 'assets/images/ausPostInternationalLogo.png'
import ausPostLogo from 'assets/images/ausPostLogo.png'
import directExpressLogo from 'assets/images/directExpressLogo.png'
import machShipLogo from 'assets/images/machship.svg'
import smartEndLogo from 'assets/images/smartEndLogo.png'
import starshipitAmazonLogo from 'assets/images/starshipitCarriers/amazon.png'
import starshipitAramaxLogo from 'assets/images/starshipitCarriers/aramax.png'
import starshipitAusPostLogo from 'assets/images/starshipitCarriers/australiaPost.png'
import starshipitBorderExpressLogo from 'assets/images/starshipitCarriers/borderExpress.png'
import starshipitCastleParcelsLogo from 'assets/images/starshipitCarriers/castleParcels.png'
import starshipitCourierPleaseLogo from 'assets/images/starshipitCarriers/courierPlease.png'
import starshipitDeutchePostLogo from 'assets/images/starshipitCarriers/deutchePost.png'
import starshipitDHLLogo from 'assets/images/starshipitCarriers/dhl.png'
import starshipitDHLExpressLogo from 'assets/images/starshipitCarriers/dhlExpress.png'
import starshipitDirectFreightExpress from 'assets/images/starshipitCarriers/directFreightExpress.png'
import starshipitDPDLogo from 'assets/images/starshipitCarriers/dpd.png'
import starshipitEvriLogo from 'assets/images/starshipitCarriers/evri.png'
import starshipitFedexLogo from 'assets/images/starshipitCarriers/fedex.png'
import starshipitHunterExpressLogo from 'assets/images/starshipitCarriers/hunterExpress.png'
import starshipitNZCouriersLogo from 'assets/images/starshipitCarriers/nzCouriers.png'
import starshipitNZPostLogo from 'assets/images/starshipitCarriers/nzPost.png'
import starshipitPBTLogo from 'assets/images/starshipitCarriers/pbt.png'
import starshipitPostHasteLogo from 'assets/images/starshipitCarriers/postHaste.png'
import starshipitQuantumLogo from 'assets/images/starshipitCarriers/quantum.png'
import starshipitRendrLogo from 'assets/images/starshipitCarriers/rendr.png'
import starshipitRoyalMailLogo from 'assets/images/starshipitCarriers/royalMail.png'
import starshipitSekoLogo from 'assets/images/starshipitCarriers/seko.png'
import starshipitSendleLogo from 'assets/images/starshipitCarriers/sendle.png'
import starshipitSherpaLogo from 'assets/images/starshipitCarriers/sherpa.png'
import starshipitStarTrackLogo from 'assets/images/starshipitCarriers/starTrack.png'
import starshipitTNTLogo from 'assets/images/starshipitCarriers/tnt.png'
import starshipitTollLogo from 'assets/images/starshipitCarriers/toll.png'
import starshipitUberLogo from 'assets/images/starshipitCarriers/uber.png'
import starshipitUPSLogo from 'assets/images/starshipitCarriers/ups.png'
import starshipitZoom2ULogo from 'assets/images/starshipitCarriers/zoom2u.png'
import starshipitLogo from 'assets/images/starshipitLogo.svg'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useCreateShipmentConsignment } from 'framework/api/methods'
import { machShipCouriers } from 'static-data/machsipCouriers'

import { Button } from 'components/atoms/Button'
import { PickUpDateModal } from 'components/pages/OrderConsignment/Modals/PickUpDateModal'

export interface ShippingLineSelection {
  ShippingLineObject?: any
  handleConsignClick?: any
  isViewOnly?: boolean
  isForceToStayOpen?: boolean
  isStayOpen?: boolean
  selectedOrderId?: any
  dateWanted?: any
  notify?: any
  callOrderShipmentsById?: any
  setMainLoadingState?: any
  isAPXDisable?: boolean
}

export const ShippingLineSelection: FC<ShippingLineSelection> = ({
  ShippingLineObject,
  handleConsignClick,
  selectedOrderId,
  isViewOnly,
  isForceToStayOpen,
  isStayOpen,
  dateWanted,
  notify,
  callOrderShipmentsById,
  setMainLoadingState,
  isAPXDisable,
}: ShippingLineSelection) => {
  const [showConsign, setShowConsign] = useState(false)
  const [showPickUpDateModal, setShowPickUpDateModal] = useState(false)
  const [pickUpdate, setPickUpdate] = useState<any>(null)
  const { shippingSummeryWarehouseCode }: any = useSelector<any>(
    (state) => state.order,
  )

  useEffect(() => {
    if (isForceToStayOpen) {
      setShowConsign(isForceToStayOpen)
    }
  }, [isForceToStayOpen, pickUpdate])

  /** Bottom container styles */
  const bottomContainerClassName = CN('bottom-container py-6', {
    'border-t border-N-200': ShippingLineObject.type !== 'DSE',
  })

  /** handle consign click */
  const handleConsign = () => {
    setMainLoadingState(true)
    const ATLValue = false
    const personToLift = 0
    const pickedDate = pickUpdate.toString()

    createShipmentConsignment(
      selectedOrderId,
      ShippingLineObject.shipmentId,
      ShippingLineObject.type,
      ShippingLineObject.productId,
      ATLValue,
      personToLift,
      pickedDate,
      ShippingLineObject?.carrierCode || null,
    )
  }

  /** API Call for Consign Shipment */
  const {
    mutate: createShipmentConsignmentMutate,
    isLoading: createShipmentConsignmentIsLoading,
  } = useCreateShipmentConsignment()

  /** Process the Consign Shipment */
  async function createShipmentConsignment(
    wereHouseOrderId: string,
    shipmentId: string,
    quoteType: string,
    productCode: string,
    ATLValue: boolean,
    personToLift: number,
    pickUpDate: string,
    starshipITCourier: string,
  ): Promise<void> {
    createShipmentConsignmentMutate(
      {
        wereHouseOrderId,
        shipmentId,
        quoteType,
        productCode,
        ATLValue,
        personToLift,
        pickUpDate,
        starshipITCourier,
        warehouseCode: shippingSummeryWarehouseCode || null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          callOrderShipmentsById()
          setShowPickUpDateModal(false)
          setMainLoadingState(false)
          window.open(successData.consignment.labelUrl, '_blank')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
          setMainLoadingState(false)
        },
      },
    )
  }

  /**  NOTE : starshipit courier type secondary image codes and logo object. this carrier codes are temporary given from client side.
   codes can be change with client's request in future. */

  const starshipitCarrierLogos: any = {
    Amazon: starshipitAmazonLogo,
    Aramex: starshipitAramaxLogo,
    AusPost: starshipitAusPostLogo,
    BorderExpress: starshipitBorderExpressLogo,
    CastleParcels: starshipitCastleParcelsLogo,
    CouriersPlease: starshipitCourierPleaseLogo,
    DeutschePost: starshipitDeutchePostLogo,
    DHL: starshipitDHLLogo,
    DHLExpress: starshipitDHLExpressLogo,
    DirectFreight: starshipitDirectFreightExpress,
    DPD: starshipitDPDLogo,
    Evri: starshipitEvriLogo,
    Fedex: starshipitFedexLogo,
    HunterExpress: starshipitHunterExpressLogo,
    NZCouriers: starshipitNZCouriersLogo,
    NZPost: starshipitNZPostLogo,
    PBT: starshipitPBTLogo,
    PostHaste: starshipitPostHasteLogo,
    Quantum: starshipitQuantumLogo,
    Rendr: starshipitRendrLogo,
    RoyalMailUK: starshipitRoyalMailLogo,
    Seko: starshipitSekoLogo,
    Sendle: starshipitSendleLogo,
    Sherpa: starshipitSherpaLogo,
    StarTrackCourier: starshipitStarTrackLogo,
    TNT: starshipitTNTLogo,
    Toll: starshipitTollLogo,
    Uber: starshipitUberLogo,
    UPS: starshipitUPSLogo,
    Zoom2U: starshipitZoom2ULogo,
  }

  return (
    <div className='main-container flex flex-col w-full px-4 bg-white mb-4 border border-N-200 rounded'>
      {ShippingLineObject.type !== 'DSE' && (
        <div
          onClick={() => {
            if (!isStayOpen) {
              setShowConsign(!showConsign)
            }
          }}
          className={CN(
            'top-container flex w-full items-center justify-between py-3',
            {
              'cursor-pointer': !isStayOpen,
            },
          )}>
          {/* Left Panel */}
          <div className='left-container flex flex-col w-2/3 py-[10px]'>
            <div className='h-auto'>
              {ShippingLineObject.type === 'AUSPOST' && (
                <img src={ausPostLogo} alt='australia-post-icon' />
              )}
              {ShippingLineObject.type === 'AUSPOST_INTERNATIONAL' && (
                <img
                  src={ausPostInternationalLogo}
                  alt='australia-post-international-icon'
                  className='h-[18px]'
                />
              )}
              {ShippingLineObject.type === 'ALLIED' && (
                <img src={alliedExpressLogo} alt='allied-express-icon' />
              )}
              {ShippingLineObject.type === 'DIRECT' && (
                <img src={directExpressLogo} alt='direct-post-icon' />
              )}
              {ShippingLineObject.type === 'SMART/SEND' && (
                <img src={smartEndLogo} alt='smart-end-icon' />
              )}
              {ShippingLineObject.type === 'STARSHIPIT' && (
                <img
                  src={starshipitLogo}
                  alt='star-ship-it-aus-post'
                  className='h-[16px]'
                />
              )}

              {/* secondary image of starshipit courier type */}
              {ShippingLineObject.type === 'STARSHIPIT' &&
              ShippingLineObject?.carrierCode &&
              starshipitCarrierLogos[ShippingLineObject?.carrierCode] !==
                undefined ? (
                <img
                  src={starshipitCarrierLogos[ShippingLineObject?.carrierCode]}
                  alt={`star-ship-it-${ShippingLineObject.carrierCode}`}
                  className='mt-2'
                />
              ) : null}

              {ShippingLineObject.type === 'MACHSHIP' && (
                <img
                  src={machShipLogo}
                  alt='mach-ship-logo'
                  className='h-[16px]'
                />
              )}
              {/* secondary image of machship courier type */}
              {ShippingLineObject.type === 'MACHSHIP' &&
              ShippingLineObject?.productName &&
              machShipCouriers?.find(
                (item) => item.label === ShippingLineObject?.productName,
              )?.label !== undefined ? (
                <img
                  src={`${
                    machShipCouriers?.find(
                      (item) => item.label === ShippingLineObject?.productName,
                    )?.url
                  }`}
                  alt={
                    machShipCouriers?.find(
                      (item) => item.label === ShippingLineObject?.productName,
                    )?.label
                  }
                  className='mt-2'
                />
              ) : null}
            </div>

            <p className='text-base font-bold text-N-700 pt-2'>
              {ShippingLineObject.type === 'MACHSHIP'
                ? ShippingLineObject.carrierService
                : ShippingLineObject.productName}
            </p>
          </div>

          {/* Right Panel */}
          <div className='left-container flex flex-col items-end w-1/3'>
            <div className='flex items-center pb-3'>
              <p className='text-xs font-semibold text-N-500 pr-1'>AU$</p>
              <p className='font-bold text-N-700'> {ShippingLineObject.cost}</p>
            </div>
            {!isViewOnly && (
              <Button
                disabled={
                  ShippingLineObject.type === 'AUSPOST_INTERNATIONAL' &&
                  isAPXDisable
                }
                onClick={(e: any) => {
                  e.stopPropagation()
                  setShowPickUpDateModal(true)
                  // handleConsignClick(
                  //   ShippingLineObject.shipmentId,
                  //   ShippingLineObject.type,
                  //   ShippingLineObject.productId,
                  // )
                }}>
                Consign
              </Button>
            )}
          </div>
        </div>
      )}
      <AnimatePresence>
        {showConsign && (
          <motion.div
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            <div className={bottomContainerClassName}>
              <p className='text-sm font-semibold text-N-700 pb-2'>
                Origin Charges
              </p>

              <div className='origin-charges-table-container flex'>
                <div className='origin-charges-table flex text-N-700 border-[1px] border-solid border-N-200 rounded overflow-hidden'>
                  {ShippingLineObject.originCharges.map((data: any, i: any) => (
                    <div className='charge-column' key={i}>
                      <div className='column-header text-center text-xs font-bold px-4 py-3 bg-N-100'>
                        {data.type}
                      </div>

                      <div className='column-price text-center text-sm font-normal px-4 py-3 '>
                        {data.price}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='bottom-extra-detail-container pt-4'>
                {ShippingLineObject?.SameDayPickup && (
                  <span className='flex text-sm font-normal text-N-500 pb-3'>
                    Same Day Pickup:
                    <p className='font-semibold text-G-500 pl-2'>Available</p>
                  </span>
                )}
                {ShippingLineObject?.SameDayPickupCutOffTime &&
                  ShippingLineObject?.SameDayPickup && (
                    <span className='flex text-sm font-normal text-N-500 pb-3'>
                      Same Day Pickup Cut Off Time:
                      <p className='font-semibold pl-2 text-N-900'>
                        {ShippingLineObject?.SameDayPickupCutOffTime}
                      </p>
                    </span>
                  )}
                {ShippingLineObject?.TransitDescription && (
                  <span className='flex text-sm font-normal text-N-500'>
                    Transit Description :
                    <p className='pl-2 font-semibold text-N-900'>
                      {ShippingLineObject?.TransitDescription}
                    </p>
                  </span>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* pick up date modal */}
      {showPickUpDateModal && (
        <PickUpDateModal
          isActive={true}
          handleConsignConfirmClick={handleConsign}
          pickUpdate={pickUpdate}
          setPickUpdate={setPickUpdate}
          ShippingLineObject={ShippingLineObject}
          isLoading={createShipmentConsignmentIsLoading}
          onCloseClick={() => {
            setShowPickUpDateModal(false)
          }}
          onOverlayClick={() => {
            setShowPickUpDateModal(false)
          }}
        />
      )}
    </div>
  )
}

ShippingLineSelection.defaultProps = {
  ShippingLineObject: undefined,
  isViewOnly: false,
  isForceToStayOpen: false,
  isAPXDisable: false,
}

export default ShippingLineSelection
