/* eslint-disable @typescript-eslint/no-array-constructor */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useCreateShipment,
  useCreateShipmentLine,
  useCreateShipmentPackage,
  useDeleteOrderShipment,
  useDeleteOrderShipmentLine,
  useDeleteOrderShipmentPackage,
  useOrderShipmentsById,
} from 'framework/api/methods'

import {
  Accordion,
  Button,
  JsonLoader,
  PopUpAlert,
  TextArea,
} from 'components/atoms'
import { ShippingSummeryPanel } from 'components/molecules/ShippingSummeryPanel'
import { AddNewPackageModal } from 'components/pages/OrderConsignment/Modals/AddNewPackageModal'
import { AddNewShipmentLineModal } from 'components/pages/OrderConsignment/Modals/AddNewShipmentLineModal'
import { AddNewShipmentModal } from 'components/pages/OrderConsignment/Modals/AddNewShipmentModal'
import { DataTable } from 'components/pages/OrderConsignment/Tables/OrderConsignmentDataTable'
import { DataTable as ProductDetailsDataTable } from 'components/pages/OrderConsignment/Tables/ProductDetailsDataTable'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

import { ScanSubmitForm } from './sections/ScanSubmitForm'
import { ShipmentTabs } from './ShipmentTabs'

import 'react-toastify/dist/ReactToastify.css'

export interface OrderConsignmentProps {
  [x: string]: any
}

export const OrderConsignment: FC<OrderConsignmentProps> = ({
  className,
}: OrderConsignmentProps) => {
  const [mainLoadingState, setMainLoadingState] = useState(true)
  const [showConsignScreen, setShowConsignScreen] = useState(false)
  const [wmsOrderId, setWmsOrderId] = useState('')
  const [isShowProductDetails, setIsShowProductDetails] =
    useState<boolean>(true)
  const [isShowNoteSection, setIsShowNoteSection] = useState<boolean>(true)

  const [selectedOrderDetails, setSelectedOrderDetails] = useState<any>({})
  const [productDetailTableValues, setProductDetailTableValues] = useState<any>(
    {},
  )
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [selectedShipmentId, setSelectedShipmentId] = useState('')
  const [shipmentSectionKey, setShipmentSectionKey] = useState(Math.random())
  const [
    availableProductDetailTableValues,
    setAvailableProductDetailTableValues,
  ] = useState<any>({})
  const [addNewShipmentSelectedRows, setAddNewShipmentSelectedRows] = useState(
    {},
  )
  const [shipmentPackageDetails, setShipmentPackageDetails] = useState<any>({
    packageType: { value: 'Carton', label: 'Carton' },
    packageQty: 1,
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  })
  const [deleteObjectDetails, setDeleteObjectDetails] = useState<any>({
    trigger: false,
    deleteData: {},
    method: '',
    deleteHeading: '',
    deleteDescription: '',
  })

  /** Modal States */
  const [isAddNewShipmentModalOpen, setIsAddNewShipmentModalOpen] =
    useState(false)
  const [isAddNewShipmentLineModalOpen, setIsAddNewShipmentLineModalOpen] =
    useState(false)
  const [isAddNewPackageModalOpen, setIsAddNewPackageModalOpen] =
    useState(false)
  const [isSelectCourierServiceModalOpen, setIsSelectCourierServiceModalOpen] =
    useState(false)
  const [isConsignWithoutQuoteModalOpen, setIsConsignWithoutQuoteModalOpen] =
    useState(false)

  /** Toaster Notifications */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** Shipment Package Modal Reset */
  const resetNewPackageModalDetails = () => {
    setShipmentPackageDetails({
      packageType: { value: 'Carton', label: 'Carton' },
      packageQty: 1,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
    })
  }

  /** Delete PopUp Modal Cancel */
  const handleDeleteCancel = () => {
    setDeleteObjectDetails({
      trigger: false,
      deleteData: {},
      method: '',
      deleteHeading: '',
      deleteDescription: '',
    })
  }

  /** Delete PopUp Modal Reset */
  const resetDeleteObjectDetails = () => {
    setDeleteObjectDetails({
      trigger: false,
      deleteData: {},
      method: '',
      deleteHeading: '',
      deleteDescription: '',
    })
  }

  /** Function On-Success For Get Order & Shipment Details By WMS # */
  const useOrderShipmentsByIdOnSuccess = (data: any) => {
    setSelectedOrderDetails(data.warehouseOrder)
    setShowConsignScreen(true)
  }

  /** Function On-Error For Get Order & Shipment Details By WMS # */
  const useOrderShipmentsByIdOnError = ({
    response: { data: errData },
  }: any) => {
    notify(errData.message || 'Error...!', 'error')
    setSelectedOrderDetails({})
    setShowConsignScreen(false)
    // 00064413-00
  }

  /** API Call for Get Order & Shipment Details By WMS # */
  const {
    isLoading: GetOrderShipmentsByIdIdIsLoading,
    refetch: callOrderShipmentsById,
  } = useOrderShipmentsById(
    {
      id: wmsOrderId,
    },
    useOrderShipmentsByIdOnSuccess,
    useOrderShipmentsByIdOnError,
  )

  /** API Call for Delete Order Shipment */
  const { mutate: deleteShipmentMutate, isLoading: deleteShipmentIsLoading } =
    useDeleteOrderShipment()

  /** Process the Delete Order Shipment */
  async function deleteShipment(orderId: string, shipmentId: string) {
    deleteShipmentMutate(
      { orderId, shipmentId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          callOrderShipmentsById()
          resetDeleteObjectDetails()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Delete Order Shipment */
  const {
    mutate: deleteOrderShipmentLineMutate,
    isLoading: deleteOrderShipmentLineIsLoading,
  } = useDeleteOrderShipmentLine()

  /** Process the Delete Order Shipment */
  async function deleteShipmentLine(
    orderId: string,
    shipmentId: string,
    shipmentLineId: string,
  ) {
    deleteOrderShipmentLineMutate(
      { orderId, shipmentId, shipmentLineId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          callOrderShipmentsById()
          resetDeleteObjectDetails()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Delete Order Shipment Package */
  const {
    mutate: deleteOrderShipmentPackageMutate,
    isLoading: deleteOrderShipmentPackageIsLoading,
  } = useDeleteOrderShipmentPackage()

  /** Process the Delete Order Shipment package */
  async function deleteOrderShipmentPackage(
    orderId: string,
    shipmentId: string,
    packageId: string,
  ) {
    deleteOrderShipmentPackageMutate(
      { orderId, shipmentId, packageId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          callOrderShipmentsById()
          resetDeleteObjectDetails()
          resetNewPackageModalDetails()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** Get All Details with Order ID Initial*/
  useEffect(() => {
    if (wmsOrderId && wmsOrderId.length > 0) {
      callOrderShipmentsById()
    }
  }, [wmsOrderId])

  /** Re-render the Shipment area when order details is updated by action */
  useEffect(() => {
    setShipmentSectionKey(Math.random())
    updateProductDetailTableValues()
  }, [selectedOrderDetails])

  /** Calculation & array handling when Add or Delete items in Shipments with Main table data  */
  const updateProductDetailTableValues = () => {
    /** 1. Push all shipment line data of each shipments to one array */
    const allEnteredProducts = new Array()
    const shipments = selectedOrderDetails?.shipments
    shipments?.map((shipment: any) => {
      shipment.shipmentLine.map((line: any) =>
        allEnteredProducts.push({
          shipment: shipment.name,
          id: line.id,
          sku: line.sku,
          description: line.description,
          enteredQty: line.qty,
        }),
      )
    })

    /** 2. Group By SKU above created array */
    const groupedProducts = new Array()
    allEnteredProducts.reduce((res, product) => {
      if (!res[product.sku]) {
        res[product.sku] = {
          sku: product.sku,
          description: product.description,
          enteredQty: 0,
        }
        groupedProducts.push(res[product.sku])
      }
      res[product.sku].enteredQty += product.enteredQty
      return res
    }, {})

    /**
     * Requirement is changed -
     * Business asked, not allow to change the quantities when creating the shipment lines
     * Then, the Quantity Picker is not handled inside the table.
     * in future if requirement is changed, Quantity Picker is only need to handle. The arrays are handled for the purpose of the Quantity changing.
     *  ------- Commented By Shane Dushyantha on 01 April 2022 -------
     *  */

    /** 3. Remove the already entered quantities from main table array & update again with calculated available Quantity */

    const toBeUpdated =
      selectedOrderDetails?.partnerOrder?.partnerOrderLines.map((item: any) => {
        if (groupedProducts.length > 0) {
          const isProduct = groupedProducts.find((product: any) => {
            return product.sku === item.warehouseSKU
          })
          if (isProduct) {
            return {
              ...item,
              availableQty: Number(item.qty) - Number(isProduct.enteredQty),
              selectedQty: Number(item.qty) - Number(isProduct.enteredQty),
            }
          } else {
            return {
              ...item,
              availableQty: Number(item.qty),
              selectedQty: Number(item.qty),
            }
          }
        } else {
          return {
            ...item,
            availableQty: Number(item.qty),
            selectedQty: Number(item.qty),
          }
        }
      })
    setProductDetailTableValues(toBeUpdated)
  }

  useEffect(() => {
    if (productDetailTableValues && productDetailTableValues.length > 0) {
      const availableProductArray = productDetailTableValues.filter(
        (item: any) => item.availableQty > 0,
      )

      if (availableProductArray.length > 0) {
        setAvailableProductDetailTableValues(availableProductArray)
      } else {
        setAvailableProductDetailTableValues([])
      }
    }
  }, [productDetailTableValues])

  /** Main Loading Status handling with every single API Loading Status's */
  useEffect(() => {
    if (
      GetOrderShipmentsByIdIdIsLoading ||
      deleteShipmentIsLoading ||
      deleteOrderShipmentLineIsLoading ||
      deleteOrderShipmentPackageIsLoading
    ) {
      setMainLoadingState(true)
    } else {
      setMainLoadingState(false)
    }
  }, [
    GetOrderShipmentsByIdIdIsLoading,
    deleteShipmentIsLoading,
    deleteOrderShipmentLineIsLoading,
    deleteOrderShipmentPackageIsLoading,
  ])

  /** Order Shipping Address */
  const { lineOne, lineTwo, city, state, country, postCode } =
    selectedOrderDetails?.partnerOrder?.shippingAddress || {}

  const fullAddressLine =
    (lineOne ? '' + lineOne : '') +
    (lineTwo ? ', ' + lineTwo : '') +
    (city ? ', ' + city : '') +
    (state ? ', ' + state : '') +
    (country ? ', ' + country : '') +
    (postCode ? ', ' + postCode : '')

  /** Order From (WareHouse) Address */
  const {
    lineOne: fromLineOne,
    lineTwo: fromLineTwo,
    city: fromCity,
    state: fromState,
    country: fromCountry,
    postCode: fromPostCode,
  } = selectedOrderDetails?.warehouse?.address || {}

  const fullFromAddressLine =
    (fromLineOne ? '' + fromLineOne : '') +
    (fromLineTwo ? ', ' + fromLineTwo : '') +
    (fromCity ? ', ' + fromCity : '') +
    (fromState ? ', ' + fromState : '') +
    (fromCountry ? ', ' + fromCountry : '') +
    (fromPostCode ? ', ' + fromPostCode : '')

  /** Handling All Delete Pop-Up Confirmations */
  const handleDeleteConfirm = () => {
    if (deleteObjectDetails.trigger) {
      /** Delete Shipment */
      if (deleteObjectDetails.method === 'deleteShipment') {
        deleteShipment(
          selectedOrderDetails.id,
          deleteObjectDetails.deleteData.id,
        )
      }

      /** Delete Shipment Line */
      if (deleteObjectDetails.method === 'deleteShipmentLine') {
        deleteShipmentLine(
          selectedOrderDetails.id,
          deleteObjectDetails.deleteData.shipmentId,
          deleteObjectDetails.deleteData.id,
        )
      }

      /** Delete Shipment Package */
      if (deleteObjectDetails.method === 'deleteShipmentPackage') {
        deleteOrderShipmentPackage(
          selectedOrderDetails.id,
          deleteObjectDetails.deleteData.shipmentId,
          deleteObjectDetails.deleteData.id,
        )
      }
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full pt-9 px-8 pb-4 overflow-y-auto styled-scroll',
        {},
        className,
      )}>
      {/* Loading Screen */}
      {mainLoadingState && (
        <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[99] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
          <JsonLoader size='xs' />
        </div>
      )}
      {!showConsignScreen && (
        /** WMS Order # Scan Area */
        <div className='flex flex-col w-full h-full justify-center items-center'>
          <ScanSubmitForm
            isBulk={false}
            setWmsOrderId={setWmsOrderId}
            isLoading={GetOrderShipmentsByIdIdIsLoading}
          />
          {/* 
          
          This section is under discussion with the business team. Not Confirmed the Screen yet.
          ------- Commented By Shane Dushyantha on 01 April 2022 -------

          <div className='flex flex-col w-full mt-8 pt-8 rounded-lg h-full bg-white'>
            <div className='flex flex-col w-[280px] pl-2 relative'>
              <input
                onChange={(e: any) => {
                  console.log(e.target.value)
                }}
                // onKeyPress={(e: any) => {
                //   handleSearchKeyPress(e)
                // }}
                type='text'
                placeholder='Search by keyword'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm mt-2'
              />
              <i
                className='ri-search-2-line absolute top-[15px] left-[22px] text-1xl text-N-200 cursor-pointer'
                onChange={(e: any) => {
                  console.log(e.target.value)
                }}></i>
            </div>
            <div>
              <DataTable
              itemData={undefined}
              setTableVariables={undefined}
              tableVariables={undefined}
              totalRowCount={undefined}
              setUpdatePartnerDrawerIsOpen={undefined}
              setSelectedRowByButtonAction={undefined}
              getPartnerTableIsLoading={undefined}
              setViewPartnerDrawerIsOpen={undefined}
              setDeletePartnerPopOutIsOpen={undefined}
              setPartnerIntegrationDrawerIsOpen={undefined}
            />
            </div>
          </div> */}
        </div>
      )}
      {showConsignScreen && (
        /** Shipment Summery */
        <div className='flex flex-col w-full h-full'>
          <Button
            onClick={() => {
              setShowConsignScreen(false)
              setWmsOrderId('')
            }}
            className='mb-2'
            size='xs'
            appearance='outline'>
            <i className='ri-arrow-left-line pr-3'></i> Back to single order
            consignment
          </Button>

          <ShippingSummeryPanel
            fromAddress={fullFromAddressLine || ''}
            toAddress={fullAddressLine || ''}
            WMSOrder={selectedOrderDetails?.orderNo}
            partner={
              `${selectedOrderDetails?.partnerOrder?.partner?.code} - ${selectedOrderDetails?.partnerOrder?.partner?.name}` ||
              ''
            }
            orderConsignmentState={capitalizeFirstLetter(
              selectedOrderDetails?.status || '',
            )}
            notify={notify}
            warehouseDetails={selectedOrderDetails?.warehouse}
          />

          {/* Product Detail Table with Accordion */}
          <div className='flex flex-col items-start justify-center bg-white mt-[30px] px-4 pt-4 pb-6 rounded-lg'>
            <Accordion
              key='Product Details'
              isOpen={isShowProductDetails}
              title='Product Details'
              subTitle={`(${productDetailTableValues?.length || 0} Products)`}
              OnClick={() => {
                setIsShowProductDetails(!isShowProductDetails)
              }}>
              <ProductDetailsDataTable
                className='pt-4'
                itemData={productDetailTableValues}
              />
            </Accordion>
          </div>

          {(selectedOrderDetails?.partnerOrder?.shippingNote ||
            selectedOrderDetails?.partnerOrder?.warehouseNote) && (
            <div className='flex flex-col items-start justify-center bg-white mt-[30px] px-4 pt-4 pb-6 rounded-lg'>
              <Accordion
                key='Notes'
                isOpen={isShowNoteSection}
                title='Notes'
                OnClick={() => {
                  setIsShowNoteSection(!isShowNoteSection)
                }}>
                <div className='grid grid-cols-2 gap-x-6 mt-6 w-full'>
                  <div className='flex flex-col'>
                    <span className='text-sm font-semibold mb-2'>
                      Customer Notes
                    </span>

                    <TextArea
                      rows={3}
                      value={
                        selectedOrderDetails?.partnerOrder?.shippingNote || '-'
                      }
                      onChange={(e: any) => {
                        //
                      }}
                      isDisabled={true}
                    />
                  </div>

                  <div className='flex flex-col'>
                    <span className='text-sm font-semibold mb-2'>
                      Instructions
                    </span>

                    <TextArea
                      rows={3}
                      value={
                        selectedOrderDetails?.partnerOrder?.warehouseNote || '-'
                      }
                      onChange={(e: any) => {
                        //
                      }}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </Accordion>
            </div>
          )}

          {/* Shipment Detail Tab View */}
          <ShipmentTabs
            setDeleteObjectDetails={setDeleteObjectDetails}
            deleteObjectDetails={deleteObjectDetails}
            selectedOrderDetails={selectedOrderDetails}
            availableProductDetailTableValues={
              availableProductDetailTableValues
            }
            setIsAddNewShipmentModalOpen={setIsAddNewShipmentModalOpen}
            setIsAddNewShipmentLineModalOpen={setIsAddNewShipmentLineModalOpen}
            selectedTabIndex={selectedTabIndex}
            shipmentSectionKey={shipmentSectionKey}
            setSelectedTabIndex={setSelectedTabIndex}
            setSelectedShipmentId={setSelectedShipmentId}
            setIsAddNewPackageModalOpen={setIsAddNewPackageModalOpen}
            setIsSelectCourierServiceModalOpen={
              setIsSelectCourierServiceModalOpen
            }
            setIsConsignWithoutQuoteModalOpen={
              setIsConsignWithoutQuoteModalOpen
            }
            isSelectCourierServiceModalOpen={isSelectCourierServiceModalOpen}
            isConsignWithoutQuoteModalOpen={isConsignWithoutQuoteModalOpen}
            notify={notify}
            callOrderShipmentsById={callOrderShipmentsById}
            SelectedShipmentId={selectedShipmentId}
            setMainLoadingState={setMainLoadingState}
            onClickBackButton={() => {
              setShowConsignScreen(false)
              setWmsOrderId('')
            }}
          />
        </div>
      )}

      {/* Delete Pop-Ups */}
      {deleteObjectDetails.trigger && (
        <PopUpAlert
          isActive={deleteObjectDetails.trigger}
          title={deleteObjectDetails?.deleteHeading}
          message={deleteObjectDetails?.deleteDescription}
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() => handleDeleteCancel()}
          onOverlayClick={() => handleDeleteCancel()}
          onConfirmClick={() => handleDeleteConfirm()}
        />
      )}

      {/* Add New Shipment Modal */}
      {isAddNewShipmentModalOpen && (
        <AddNewShipmentModal
          isActive={isAddNewShipmentModalOpen}
          notify={notify}
          selectedOrderDetails={selectedOrderDetails}
          callOrderShipmentsById={callOrderShipmentsById}
          setAddNewShipmentSelectedRows={setAddNewShipmentSelectedRows}
          addNewShipmentSelectedRows={addNewShipmentSelectedRows}
          useCreateShipment={useCreateShipment}
          setSelectedTabIndex={setSelectedTabIndex}
          availableProductDetailTableValues={availableProductDetailTableValues}
          setAvailableProductDetailTableValues={
            setAvailableProductDetailTableValues
          }
          onCloseClick={() => {
            setIsAddNewShipmentModalOpen(false)
          }}
          onOverlayClick={() => {
            setIsAddNewShipmentModalOpen(false)
          }}
        />
      )}

      {/* Add New Shipment Line Modal */}
      {isAddNewShipmentLineModalOpen && (
        <AddNewShipmentLineModal
          isActive={isAddNewShipmentLineModalOpen}
          notify={notify}
          selectedOrderDetails={selectedOrderDetails}
          callOrderShipmentsById={callOrderShipmentsById}
          setAddNewShipmentSelectedRows={setAddNewShipmentSelectedRows}
          addNewShipmentSelectedRows={addNewShipmentSelectedRows}
          useCreateShipmentLine={useCreateShipmentLine}
          setSelectedTabIndex={setSelectedTabIndex}
          availableProductDetailTableValues={availableProductDetailTableValues}
          selectedShipmentId={selectedShipmentId}
          setAvailableProductDetailTableValues={
            setAvailableProductDetailTableValues
          }
          onCloseClick={() => {
            setIsAddNewShipmentLineModalOpen(false)
          }}
          onOverlayClick={() => {
            setIsAddNewShipmentLineModalOpen(false)
          }}
        />
      )}

      {/* Add New Shipment Package Modal */}
      {isAddNewPackageModalOpen && (
        <AddNewPackageModal
          isActive={isAddNewPackageModalOpen}
          notify={notify}
          onCloseClick={() => {
            setIsAddNewPackageModalOpen(false)
            resetNewPackageModalDetails()
          }}
          setShipmentPackageDetails={setShipmentPackageDetails}
          shipmentPackageDetails={shipmentPackageDetails}
          useCreateShipmentPackage={useCreateShipmentPackage}
          callOrderShipmentsById={callOrderShipmentsById}
          selectedShipmentId={selectedShipmentId}
          selectedOrderDetails={selectedOrderDetails}
        />
      )}

      {/* Toaster */}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

OrderConsignment.defaultProps = {}

export default OrderConsignment
