export const columns = [
  {
    customWidth: 140,
    Header: () => 'Product Code',
    accessor: 'sku',
    Cell: ({ row }) => <div>{row.original.sku}</div>,
  },
  {
    customWidth: 311,
    Header: () => 'Product Name',
    accessor: 'description',
    Cell: ({ row }) => <div>{row.original.description}</div>,
  },
  {
    customWidth: 63,
    Header: 'Qty',
    accessor: 'qty',
    Cell: ({ row }) => <div>{row.original.qty}</div>,
  },
  {
    customWidth: 104,
    Header: () => '',
    accessor: 'actions',
    isAction: true,
  },
]

export default columns
