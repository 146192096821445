import React, { FC } from 'react'
import CN from 'classnames'

export interface ShippingDetailViewSectionProps {
  [x: string]: any
}

/** This is ShippingDetailViewSection */

export const ShippingDetailViewSection: FC<ShippingDetailViewSectionProps> = ({
  orderDetails,
}: ShippingDetailViewSectionProps) => {
  const ViewSection = (
    heading: string,
    value: string,
    viewAlignment: string,
  ) => {
    return (
      <div
        className={CN('flex flex-col', {
          'w-1/2 pr-2': viewAlignment === 'left',
          'w-1/2 pl-2': viewAlignment === 'right',
          'w-full': viewAlignment === 'full',
        })}>
        <span className='text-N-500 text-sm'>{heading}</span>
        <span className='text-N-700 font-semibold pt-[2px]'>{value}</span>
      </div>
    )
  }

  const { lineOne, lineTwo, city, state, country, postCode } =
    orderDetails?.shippingAddress || {}

  const fullAddressLine =
    (lineOne ? '' + lineOne : '') +
    (lineTwo ? ', ' + lineTwo : '') +
    (city ? ', ' + city : '') +
    (state ? ', ' + state : '') +
    (country ? ', ' + country : '') +
    (postCode ? ', ' + postCode : '')

  return (
    <div className='flex w-full flex-col px-4 pt-[10px] mb-7'>
      <div className='flex w-full'>
        {ViewSection(
          'Customer Name:',
          `${orderDetails?.customerFirstName} ${orderDetails?.customerLastName}`,
          'left',
        )}
        {ViewSection(
          'Customer Phone:',
          `${orderDetails?.customerPhone || '-'}`,
          'right',
        )}
      </div>
      <div className='flex w-full pt-4'>
        {ViewSection(
          'Customer Email:',
          `${orderDetails?.customerEmail || '-'}`,
          'left',
        )}
        {ViewSection('Shipping Address:', fullAddressLine, 'right')}
      </div>
      <div className='flex w-full pt-4'>
        {ViewSection(
          'State Code:',
          `${orderDetails?.shippingAddress?.stateCode || '-'}`,
          'left',
        )}
        {ViewSection(
          'Country Code:',
          `${orderDetails?.shippingAddress?.countryCode || '-'}`,
          'right',
        )}
      </div>
      <div className='flex w-full pt-4'>
        {ViewSection(
          'Customer Notes:',
          `${orderDetails?.shippingNote || '-'}`,
          'left',
        )}
        {ViewSection(
          'Internal Notes:',
          `${orderDetails?.warehouseNote || '-'}`,
          'right',
        )}
      </div>
    </div>
  )
}

ShippingDetailViewSection.defaultProps = {}

export default ShippingDetailViewSection
